var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Split button link support" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeButtonLink) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("To convert this button into a link or ")]),
        _c("code", [_vm._v("<router-link>")]),
        _c("span", [_vm._v(", specify the href via the ")]),
        _c("code", [_vm._v("split-href")]),
        _c("span", [_vm._v(" prop or a router link ")]),
        _c("code", [_vm._v("to")]),
        _c("span", [_vm._v(" value via the ")]),
        _c("code", [_vm._v("split-to")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "div",
        [
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                split: "",
                right: "",
                variant: "primary",
                "split-href": "#foo/bar",
                text: "Split Link"
              }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Action ")]),
              _c("b-dropdown-item", [_vm._v(" Another action ")]),
              _c("b-dropdown-item", [_vm._v(" Something else here... ")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }