var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Basic" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDropdownBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _vm._v(
          " Dropdowns are toggleable, contextual overlays for displaying lists of links and actions in a dropdown menu format. "
        )
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "dropdown-1", text: "Primary", variant: "primary" }
            },
            [
              _c("b-dropdown-item", [_vm._v("Option 1")]),
              _c("b-dropdown-item", [_vm._v("Option 2")]),
              _c("b-dropdown-item", [_vm._v("Option 3")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                id: "dropdown-7",
                text: "Secondary",
                variant: "secondary"
              }
            },
            [
              _c("b-dropdown-item", [_vm._v("Option 1")]),
              _c("b-dropdown-item", [_vm._v("Option 2")]),
              _c("b-dropdown-item", [_vm._v("Option 3")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "dropdown-3", text: "Success", variant: "success" }
            },
            [
              _c("b-dropdown-item", [_vm._v("Option 1")]),
              _c("b-dropdown-item", [_vm._v("Option 2")]),
              _c("b-dropdown-item", [_vm._v("Option 3")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "dropdown-5", text: "Danger", variant: "danger" }
            },
            [
              _c("b-dropdown-item", [_vm._v("Option 1")]),
              _c("b-dropdown-item", [_vm._v("Option 2")]),
              _c("b-dropdown-item", [_vm._v("Option 3")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "dropdown-4", text: "Warning", variant: "warning" }
            },
            [
              _c("b-dropdown-item", [_vm._v("Option 1")]),
              _c("b-dropdown-item", [_vm._v("Option 2")]),
              _c("b-dropdown-item", [_vm._v("Option 3")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "dropdown-6", text: "Info", variant: "info" }
            },
            [
              _c("b-dropdown-item", [_vm._v("Option 1")]),
              _c("b-dropdown-item", [_vm._v("Option 2")]),
              _c("b-dropdown-item", [_vm._v("Option 3")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { id: "dropdown-8", text: "Dark", variant: "dark" }
            },
            [
              _c("b-dropdown-item", [_vm._v("Option 1")]),
              _c("b-dropdown-item", [_vm._v("Option 2")]),
              _c("b-dropdown-item", [_vm._v("Option 3")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }