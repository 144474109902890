var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Block level dropdowns" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBlock) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "By default dropdowns act like buttons and are displayed inline. "
          )
        ]),
        _c("span", [
          _vm._v(
            "To create block level dropdowns (that span to the full width of a parent) set the "
          )
        ]),
        _c("code", [_vm._v("block")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "div",
        [
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "mt-2",
              attrs: {
                text: "Block Level Dropdown",
                block: "",
                right: "",
                variant: "primary"
              }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Action ")]),
              _c("b-dropdown-item", [_vm._v(" Another action ")]),
              _c("b-dropdown-item", [_vm._v(" Something else here ")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "mt-2",
              attrs: {
                text: "Block Level Split Dropdown",
                block: "",
                split: "",
                right: "",
                "split-variant": "outline-primary",
                variant: "outline-primary"
              }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Action ")]),
              _c("b-dropdown-item", [_vm._v(" Another action ")]),
              _c("b-dropdown-item", [_vm._v(" Something else here... ")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }