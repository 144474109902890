var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Variations" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDropdownVariation) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "Group a set of dropdown sub components with an optional associated header. Place a "
          )
        ]),
        _c("code", [_vm._v("<b-dropdown-divider>")]),
        _c("span", [_vm._v(" between your")]),
        _c("code", [_vm._v("<b-dropdown-group>")]),
        _c("span", [
          _vm._v(" and other groups or non-grouped dropdown contents.")
        ])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                id: "dropdown-grouped",
                variant: "primary",
                right: "",
                text: "Group"
              }
            },
            [
              _c(
                "b-dropdown-group",
                { attrs: { id: "dropdown-group-1", header: "Group 1" } },
                [
                  _c("b-dropdown-item", [_vm._v("Option 1")]),
                  _c("b-dropdown-item", [_vm._v("Option 2")])
                ],
                1
              ),
              _c("b-dropdown-divider"),
              _c(
                "b-dropdown-group",
                { attrs: { id: "dropdown-group-2", header: "Group 2" } },
                [
                  _c("b-dropdown-item", [_vm._v("Option 1")]),
                  _c("b-dropdown-item", [_vm._v("Option 2")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "dropdown-icon-wrapper",
              attrs: { id: "dropdown-grouped", variant: "primary", right: "" },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "mr-1" }, [_vm._v("Volume")]),
                      _c("feather-icon", {
                        staticClass: "align-middle",
                        attrs: { icon: "Volume1Icon", size: "16" }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-dropdown-item",
                [
                  _c("feather-icon", {
                    attrs: { icon: "Volume1Icon", size: "18" }
                  })
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                [
                  _c("feather-icon", {
                    attrs: { icon: "Volume2Icon", size: "18" }
                  })
                ],
                1
              ),
              _c(
                "b-dropdown-item",
                [
                  _c("feather-icon", {
                    attrs: { icon: "VolumeXIcon", size: "18" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              ref: "dropdown",
              attrs: {
                id: "dropdown-form",
                right: "",
                variant: "primary",
                text: "Form"
              }
            },
            [
              _c(
                "b-dropdown-form",
                { staticClass: "py-1" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Email",
                        "label-for": "dropdown-form-email"
                      },
                      on: {
                        submit: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                        }
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "dropdown-form-email",
                          size: "sm",
                          placeholder: "email@example.com"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Password",
                        "label-for": "dropdown-form-password"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "dropdown-form-password",
                          type: "password",
                          size: "sm",
                          placeholder: "Password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [_c("b-form-checkbox", [_vm._v(" Remember me ")])],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", size: "sm" },
                      on: { click: _vm.onClick }
                    },
                    [_vm._v(" Sign In ")]
                  )
                ],
                1
              ),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", [_vm._v("New around here? Sign up")]),
              _c("b-dropdown-item", [_vm._v("Forgot Password?")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }