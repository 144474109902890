var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Dropdown sub-component color variants" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSubComponent) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Many of the supported dropdown ")]),
        _c("code", [_vm._v("sub-components")]),
        _c("span", [_vm._v(" provide a ")]),
        _c("code", [_vm._v("variant")]),
        _c("span", [_vm._v(" prop for controlling their text color.")])
      ]),
      _c(
        "div",
        [
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                text: "Block Level Dropdown Menu",
                variant: "primary",
                right: "",
                "menu-class": "w-100"
              }
            },
            [
              _c("b-dropdown-item", [_vm._v("Hold")]),
              _c("b-dropdown-item", { attrs: { variant: "success" } }, [
                _vm._v(" Approve ")
              ]),
              _c("b-dropdown-item", { attrs: { variant: "danger" } }, [
                _vm._v(" Remove ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }