var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("dropdown-basic"),
          _c("dropdown-split"),
          _c("dropdown-outline"),
          _c("dropdown-flat"),
          _c("dropdown-gradient"),
          _c("dropdown-size"),
          _c("dropdown-direction"),
          _c(
            "b-row",
            { staticClass: "match-height" },
            [
              _c("b-col", { attrs: { md: "6" } }, [_c("dropdown-block")], 1),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [_c("dropdown-variation")],
                1
              ),
              _c("b-col", { attrs: { md: "6" } }, [_c("dropdown-link")], 1),
              _c("b-col", { attrs: { md: "6" } }, [_c("dropdown-lazy")], 1)
            ],
            1
          ),
          _c("dropdown-sub-component")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }