var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Lazy dropdown" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeLazy) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v(" You can instruct")]),
        _c("code", [_vm._v("<b-dropdown>")]),
        _c("span", [
          _vm._v(
            " to render the menu contents only when it is shown by setting the "
          )
        ]),
        _c("code", [_vm._v("lazy")]),
        _c("span", [_vm._v(" prop to true.")])
      ]),
      _c(
        "div",
        [
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                text: "Lazy Dropdown",
                variant: "primary",
                lazy: "",
                right: ""
              }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Action ")]),
              _c("b-dropdown-item", [_vm._v(" Another action ")]),
              _c("b-dropdown-item", [_vm._v(" Something else here ")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }