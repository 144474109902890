var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Split Dropdowns" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDropdownSplit) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v(
            "Create a split dropdown button, where the left button provides standard "
          )
        ]),
        _c("code", [_vm._v("click")]),
        _c("span", [
          _vm._v(
            " event and link support, while the right hand side is the dropdown menu toggle button. Use prop "
          )
        ]),
        _c("code", [_vm._v("split")]),
        _c("span", [_vm._v(" for split dropdown.")])
      ]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                split: "",
                text: "Primary",
                variant: "primary",
                right: ""
              }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Option 1 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 2 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 3 ")]),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", [_vm._v("Separated link")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                split: "",
                text: "Secondary",
                variant: "secondary",
                right: ""
              }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Option 1 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 2 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 3 ")]),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", [_vm._v("Separated link")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                split: "",
                text: "Success",
                variant: "success",
                right: ""
              }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Option 1 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 2 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 3 ")]),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", [_vm._v("Separated link")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { split: "", text: "Danger", variant: "danger", right: "" }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Option 1 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 2 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 3 ")]),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", [_vm._v("Separated link")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: {
                split: "",
                text: "Warning",
                variant: "warning",
                right: ""
              }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Option 1 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 2 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 3 ")]),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", [_vm._v("Separated link")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { split: "", text: "Info", variant: "info", right: "" }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Option 1 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 2 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 3 ")]),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", [_vm._v("Separated link")])
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { split: "", text: "Dark", right: "", variant: "dark" }
            },
            [
              _c("b-dropdown-item", [_vm._v(" Option 1 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 2 ")]),
              _c("b-dropdown-item", [_vm._v(" Option 3 ")]),
              _c("b-dropdown-divider"),
              _c("b-dropdown-item", [_vm._v("Separated link")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }